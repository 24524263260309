import React from 'react';
import { Row, Col, Card, Divider } from 'antd';

import { Loading, displayDataCheck, MetaTags, UserLayout, displayBoolean } from '../../look';

import * as ROUTE from '../route';
import EditProfile from '../containers/EditProfile';
import TeamComponent from '../../dashboard/components/TeamComponent';
import { USER_IMG } from '../../../config';

const ProfileView = props => {
  const { me, loading, history } = props;
  console.log('me data here ::', me)
  return (
    <>
      <UserLayout active={ROUTE.profile} me={me}>
        <MetaTags title="Profile" description="This is profile page" />
        {loading && <Loading />}
        {!loading && me && (
          <>
            <Row>
              <Col lg={{ span: 15, offset: 1 }} xs={{ span: 22, offset: 1 }}>
                <Row>
                  <Col lg={0} span={24} align="flex-start">
                    <h2 style={{ fontWeight: 'normal', margin: 0 }}>My Profile</h2>
                    <br />
                  </Col>
                </Row>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                  <Col lg={{ span: 3, offset: 0 }} xs={{ span: 4, offset: 1 }}>
                    <Row justify="end">
                      <Col lg={15} xs={20}>
                        <img
                          alt=""
                          src={me?.profile?.profileImage || USER_IMG}
                          style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col style={{ marginLeft: '8px' }} lg={{ span: 8, offset: 1 }} xs={{ span: 12, offset: 1 }}>
                    <Row>
                      <Col span={24}>
                        {/* <Row> */}
                        <h3 style={{ margin: 0, fontWeight: 'bold' }}>
                          {displayDataCheck(`${me.firstName} ${me.lastName}`)}
                          {displayBoolean(me.verified)}
                        </h3>
                        {/* </Row> */}
                        {/* <h4>position</h4> */}
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={{ span: 12, offset: 0 }} xs={{ span: 6, offset: 0 }}>
                    <Row justify="center">
                      <Col span={20}>
                        <EditProfile history={history} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={24}>
                    <Row justify="center" gutter={[24, 24]}>
                      <Col lg={10} xs={24}>
                        <Card hoverable style={{ borderRadius: '2%' }} bordered={false}>
                          <Row justify="center" gutter={8} style={{ lineHeight: '2.2' }}>
                            <Col span={24} align="center">
                              <h3 style={{ margin: 0 }}>
                                <strong>Bio 1</strong>
                              </h3>
                              <Divider style={{ width: '70%', minWidth: '70%', margin: '12px 0' }} />
                            </Col>
                            <Col align="end" span={12}>
                              Gender :
                              <br />
                              DOB :
                              <br />
                              Married :
                            </Col>
                            <Col align="left" span={12}>
                              {displayDataCheck(me.profile && me.profile.gender)}
                              <br />
                              {displayDataCheck(me.profile && me.profile.dob)}
                              <br />
                              {displayDataCheck(me.profile && me.profile.married ? 'YES' : 'NO')}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col lg={14} xs={24}>
                        <Card hoverable style={{ borderRadius: '2%' }} bordered={false}>
                          <Row justify="center" gutter={8} style={{ lineHeight: '2.2' }}>
                            <Col span={24} align="center">
                              <h3 style={{ margin: 0 }}>
                                <strong>Bio2</strong>
                              </h3>
                              <Divider style={{ width: '70%', minWidth: '70%', margin: '12px 0' }} />
                            </Col>
                            <Col align="end" span={12}>
                              Village :
                              <br />
                              District :
                              <br />
                              Phone number :
                            </Col>
                            <Col align="left" span={12}>
                              {displayDataCheck(me.profile && me.profile.village)}
                              <br />
                              {displayDataCheck(me.profile && me.profile.district)}
                              <br />
                              {displayDataCheck(me.profile && me.profile.phoneNumber)}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <Row justify="center" gutter={[24, 24]}>
                      <Col lg={24} xs={24}>
                        <Card hoverable style={{ borderRadius: '2%' }} bordered={false}>
                          <Row justify="center" gutter={8} style={{ lineHeight: '2.2' }}>
                            <Col span={24} align="center">
                              <h3 style={{ margin: 0 }}>
                                <strong>Residence</strong>
                              </h3>
                              <Divider style={{ width: '80%', minWidth: '80%' }} />
                            </Col>
                            <Col align="end" span={12}>
                              Residence during secondary school :
                              <br />
                              Residence during college university :
                              <br />
                              Residence during major part of career :
                              <br />
                              Address :
                            </Col>
                            <Col align="left" span={12}>
                              {displayDataCheck(me.profile && me.profile.residenceDuringSecondarySchool)}
                              <br />
                              {displayDataCheck(me.profile && me.profile.residenceDuringCollegeUniversity)}
                              <br />
                              {displayDataCheck(me.profile && me.profile.residenceDuringMajorPartOfCareer)}
                              <br />
                              {displayDataCheck(me.profile && me.profile.address)}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <TeamComponent me={me} />
            </Row>
          </>
        )}
      </UserLayout>
    </>
  );
};

export default ProfileView;
