import React, { useState } from 'react';
import { Col, Button, Card, Modal, Row, Tabs, Empty } from 'antd';
import styled from 'styled-components';

import { compose, getItem } from '../../core';
import { RECENT_TOKEN } from '../../../config';
import STORY_ROUTE from '../../story/route';

import RecentToolComponent from './RecentToolComponent';
import NewTools from './NewTools';

const ResiliencePulseCheck = props => {
  const [visible, setVisible] = useState(false);
  const [recentToken, setRecentToken] = useState(false);
  const handleLoggedIn = React.useRef(() => { });

  handleLoggedIn.current = async () => {
    try {
      const getrecentToken = await getItem(RECENT_TOKEN);
      if (getrecentToken) setRecentToken(JSON.parse(getrecentToken()));
    } catch (error) {
      alert(error)
     }
  };

  React.useEffect(() => {
    handleLoggedIn.current();
  }, []);

  const OperationsSlot = {
    right: (
      <Button type="link" size="small" /* href={STORY_ROUTE.allStories} */ disabled>
        See all
      </Button>
    )
  };
  console.log('recentToken = ::', recentToken, OperationsSlot)
  return (
    <>
      <Col span={24}>
        {/* <Tooltip title="This feature is comming soon!"> */}
        <Card
          bodyStyle={{
            boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
            borderRadius: '10px',
            paddingBottom: 0,
            paddingTop: 0,
            cursor: 'pointer'
          }}
          bordered={true}
          hoverable
          onClick={() => setVisible(true)}
        >
          <h2 align="center" style={{ margin: '1em 0', fontWeight: 'normal', color: visible ? '#881FCC' : '#222' }}>
            Helpful Tools
          </h2>
        </Card>
        {/* </Tooltip> */}
      </Col>

      <Modal
        footer={null}
        centered
        visible={visible}
        width={'50%'}
        destroyOnClose={true}
        bodyStyle={{ background: '#F9F9F8', minHeight: '50vh' }}
        onCancel={() => setVisible(false)}
      // bodyStyle={{ padding: '0px' }}
      >
        <h3>Helpful Tools</h3>
        <br />
        <Row>
          <Col span={24}>
            <NewTab defaultActiveKey="1" tabBarExtraContent={OperationsSlot}>
              <Tabs.TabPane tab="New Tools" key="1">
                <NewTools orderBy={['-pubDate']} first={5} />
              </Tabs.TabPane>

              <Tabs.TabPane tab="Recently used" key="2">
                {recentToken?.length > 0 ? (
                  recentToken.map((id, idx) => (
                    <>
                      <Row gutter={24} align="middle">
                        <Col span={2} align="center">
                          <span style={{ borderRadius: '50%', padding: '10px 13px', border: '1px solid #CACACA' }}>
                            {idx + 1}
                          </span>
                        </Col>
                        <Col span={21}>
                          <Card
                            bordered={false}
                            style={{ boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)', borderRadius: '10px' }}
                            bodyStyle={{ padding: '12px' }}
                          >
                            <Row justify="space-between" gutter={[12, 12]}>
                              <Col span={24}>
                                <RecentToolComponent id={id} />
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                        <Col span={1} />
                      </Row>
                      <br />
                    </>
                  ))
                ) : (
                  <div align="center">
                    <Empty />
                  </div>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab="Currently Popular" key="3">
                <div align="center">
                  <Empty />
                </div>
              </Tabs.TabPane>
            </NewTab>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default compose()(ResiliencePulseCheck);

export const NewTab = styled(Tabs)`
  .ant-tabs-nav::before {
    border-bottom: 0px solid #f0f0f0;
  }
  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab.ant-tabs-tab-active {
          background-color: #fff;
          padding: 10px 35px;
          box-shadow: 5px 0px 25px 3px rgba(32, 32, 32, 0.1);
          border-radius: 8px;
          margin: 12px;
        }
        .ant-tabs-tab {
          padding: 10px 35px;
          margin: 12px;
          color: #afb0a2;
        }
        .ant-tabs-tab:hover {
          background-color: #fff;
          box-shadow: 5px 0px 25px 0px rgba(32, 32, 32, 0.1);
          border-radius: 8px;
          color: #b0cb1f;
        }
        .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
          display: none;
        }
      }
    }
  }
`;
