import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import NotFound from '../../page/containers/NotFound';
import { withMe } from '../../user/containers/UserOperations';
import USER_ROUTE from '../../user/route';
import { getItem } from '../../core';
import DASHBOARD_ROUTE from "../../dashboard/route"

import PageLayout from './Layout';

const Routes = props => {
  const { component: Component, loading, me, protect, path, ...rest } = props;
  let history = useHistory();

  if (protect && getItem('accessToken') === null) history.push(USER_ROUTE.login);
  const redirectIfLoggedIn = async () =>{
    await getItem("accessToken").then((data)=>{
      if(data){
        history.push(DASHBOARD_ROUTE.personal);
      }
    })
  }
  if(path === "/") redirectIfLoggedIn();
    return (
    <Route
      key={rest.name}
      {...rest}
      render={matchProps => (
        // <Spin spinning={loading} size="large">
          <PageLayout {...rest}>
            {rest.admin ? (
              !loading &&
              (me ? (
                !me.isSuperuser ? (
                  <NotFound status={'403'} />
                ) : (
                  <Component {...matchProps} {...rest} me={me} />
                )
              ) : (
                typeof me !== undefined && history.push(USER_ROUTE.login)
              ))
            ) : (
              <Component {...matchProps} {...rest} me={me} />
            )}
          </PageLayout>
        // </Spin>
      )}
    />
  );
};

export default withMe(Routes);
