import React from 'react';
import { Row, Col, Card, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import * as ROUTE from '../route';
import MANAGER_ROUTE from '../../manager/route';
import { getIntFromString } from '../../look/';
import { compose } from '../../core';
import { withMe } from '../../user/containers/UserOperations';

const DashboardMenuBar = props => {
  const { active, me } = props;
  let history = useHistory();

  const handleSelect = params => {
    history.push(params.key);
  };

  const visibleManager = managers => {
    let isVisible = false;
    managers &&
      managers.map(edge => {
        const head = edge?.managers;
        head.edges.map(edge => {
          if (getIntFromString(me?.id) === getIntFromString(edge?.node?.user?.id)) isVisible = isVisible || true;
        });
      });
    return isVisible;
  };

  const visibleVertical = verticals => {
    let isVisible = false;
    verticals &&
      verticals.length > 0 &&
      verticals.map(({ heads }) => {
        heads.edges &&
          heads.edges.length > 0 &&
          heads.edges.map(edge => {
            if (getIntFromString(me?.id) === getIntFromString(edge?.node?.user?.id)) isVisible = isVisible || true;
          });
      });
    return isVisible;
  };

  const visibleHR = employees => {
    let isVisible = false;
    employees &&
      employees.map(edge => {
        if (
          getIntFromString(me?.id) === getIntFromString(edge?.ceo?.user?.id) ||
          getIntFromString(me?.id) === getIntFromString(edge?.poc?.user?.id)
        )
          isVisible = isVisible || true;
      });
    return isVisible;
  };

  const TABS = [
    {
      label: 'Personal',
      icon: <UserOutlined style={{ fontSize: '30px', marginRight: 0 }} />,
      key: ROUTE.personal,
      visible: true
    },
    {
      label: 'Manager',
      icon: <UserOutlined style={{ fontSize: '30px', marginRight: 0 }} />,
      key: MANAGER_ROUTE.manager,
      // disabled: true
      visible:
        visibleManager(me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => node)) ||
        visibleVertical(me?.employee?.verticalHeadEmployee?.edges?.map(({ node }) => node)) ||
        visibleHR(me?.employee?.organizationSet?.edges?.map(({ node }) => node))
    },
    // {
    //   label: 'Vertical',
    //   icon: <UserOutlined style={{ fontSize: '30px', marginRight: 0 }} />,
    //   key: ROUTE.vertical,
    //   disabled: true,
    //   visible:
    //     visibleHR(me?.employee?.organizationSet?.edges?.map(({ node }) => node)) ||
    //     visibleVertical(me?.employee?.verticalHeadEmployee?.edges?.map(({ node }) => node))
    // },
    // {
    //   label: 'HR',
    //   icon: <UserOutlined style={{ fontSize: '30px', marginRight: 0 }} />,
    //   key: ROUTE.hr,
    //   disabled: true,
    //   visible: visibleHR(me?.employee?.organizationSet?.edges?.map(({ node }) => node))
    // }
  ];

  return (
    <>
      <Row gutter={[24, 12]}>
        <Col lg={0} span={24} align="flex-start">
          <h2 style={{ /* fontWeight: 'normal',  */ margin: 0 }}>Resilience Toolkit</h2>
        </Col>
        <Col span={24}>
          <Card
            bodyStyle={{
              boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
              borderRadius: '10px',
              padding: '24px 0 0 0'
            }}
            bordered={false}
          >
            <Menu mode="horizontal" defaultSelectedKeys={[`${active}`]} onSelect={handleSelect}>
              {TABS.map(t =>
                t.visible ? (
                  <Menu.Item key={t.key} align="center" style={{ width: '18%' }} disabled={t.disabled}>
                    {t.icon}
                    <h3 style={{ fontWeight: 'normal' }}>{t.label}</h3>
                  </Menu.Item>
                ) : null
              )}
            </Menu>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default compose(withMe)(DashboardMenuBar);
