import React from 'react';
import { Card, Row, Col, Menu, Drawer, Button } from 'antd';
import { BarsOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import USER_ROUTE from '../../user/route';
import DASHBOARD_ROUTE from '../../dashboard/route';
import { ReactComponent as TOOLKIT } from "../../assets/home_resilience_toolkit.svg"
import { ReactComponent as SURVEY } from "../../assets/home_surveys.svg"
import { ReactComponent as REPORT } from "../../assets/home_my_reports.svg"
import Logo from "../../assets/logo_v2.png"
import "../styles/UserLayout.css"
import SURVEY_ROUTE from '../../survey/route';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const UserLayout = props => {
  const { children, active, me } = props;
  const [visible, setVisible] = React.useState(false);
  let history = useHistory();

  const onSelect = params => {
    history.push(params.key);
  };

  const getIcon = (Icon) => {
    return <Icon className='home-sidebar-icons' style={{ width: '24px', height: '24px' }} />
  }

  const MenuItems = (
    <>
      <Menu.Item
        key={DASHBOARD_ROUTE.personal /* getDashboardkey */}
        className="home-sidebar-menu"
        icon={getIcon(TOOLKIT)}
      >
        Resilience Toolkit
      </Menu.Item>
      <Menu.Item key={SURVEY_ROUTE.allOrganizationSurvey} className="home-sidebar-menu" icon={getIcon(SURVEY)} >
        Survey's
      </Menu.Item>
      <Menu.Item key={USER_ROUTE.myResponse} className="home-sidebar-menu" icon={getIcon(REPORT)} >
        My Reports
      </Menu.Item>
    </>
  );

  return (
    <Row className='home-body-container'>
      <div className="sidebar-image-container" >
          <img src={Logo} alt="logo" style={{ height: "70%", zIndex: 11 }} onClick={()=>{history.push(DASHBOARD_ROUTE.personal)}}/>
      </div>
      <Col lg={{ span: 4, offset: 0 }} xs={{ span: 0, offset: 0 }}>
        <Card
          bodyStyle={{
            boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
            height: '100vh',
            width: '275px',
            zIndex: 10,
            position: "fixed",
            top: "0px",
            background: "#000000",
            display: "flex",
            alignItems: "center"
          }}
          bordered={false}
        >
          <Row justify="end" style={{ width: "100%", backgroundColor: "#000" }}>
            <Menu mode="inline" defaultSelectedKeys={[`${active}`]} onSelect={onSelect} style={{ backgroundColor: "#000", borderRight: "unset" }}>
              {MenuItems}
            </Menu>
          </Row>
        </Card>
      </Col>
      <Col lg={{ span: 0, offset: 0 }} xs={{ span: 24, offset: 0 }}>
        <Drawer
          className='sidebar-drawer'
          width="190px"
          bodyStyle={{ padding: '24px 0px' }}
          placement="left"
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
        >
          <Menu mode="inline" defaultSelectedKeys={[`${active}`]} onSelect={onSelect}>
            {MenuItems}
          </Menu>
        </Drawer>
      </Col>
      <Col lg={{ span: 15, offset: 7 }} xl={{ span: 17, offset: 6 }} xs={{ span: 22, offset: 1 }}>
        {children}
      </Col>
      <Col lg={{ span: 1, offset: 0 }} xs={{ span: 0, offset: 0 }} />
    </Row>
  );
};

export default UserLayout;