module.exports = {
  PAGINATION_LIMIT: 12,
  REPORT_AVAILABLE_FOR: [1, 2],
  IS_ADMIN_DROPDOWN: false,
  STORY_BANNER_HEIGHT: 250,
  NEW_LAYOUT: true,

  PRIMARY_CLR: '#B0CB1F',
  IMG:
    'https://res.cloudinary.com/brainayan/image/upload/v1629798644/g5vqlqdawohncfcedz44.jpg' ||
    'http://scanivalve.com/wp-content/plugins/lightbox/images/No-image-found.jpg',
  USER_IMG: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',

  // localStorage varaiable
  RECENT_TOKEN: 'recentToken',

  // Env variables
  NODE_ENV: process.env.NODE_ENV,

  BACKEND_URI: process.env.REACT_APP_BACKEND_URI || '',
  IS_MAINTENANCE: process.env.REACT_APP_IS_MAINTENANCE,

  CLOUDINARY_CLOUDNAME: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
  CLOUDINARY_PRESET: process.env.REACT_APP_CLOUDINARY_PRESET
};
