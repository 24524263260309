import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { ME_QUERY } from '../../user/graphql/MeQuery.gql';
import { useHistory } from 'react-router-dom';

import { BackTop, Affix, Row, Col, Layout, Menu, Button, Drawer, Tooltip } from 'antd';
import {
  BarsOutlined,
  HomeOutlined,
  UserOutlined,
  LoginOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  DeploymentUnitOutlined,
  ApiOutlined,
  ReconciliationOutlined,
  MenuOutlined,
  ImportOutlined,
  ArrowUpOutlined
} from '@ant-design/icons';
import styled from 'styled-components';

import { BACKEND_URI, IS_ADMIN_DROPDOWN, NODE_ENV } from '../../../config';
import { getItem, compose, removeItem } from '../../core';
import Logo from '../../assets/logo_v2.png';
import Logo_Dark from '../../assets/logo_dark.png'
import { ReactComponent as TOOLKIT } from "../../assets/home_resilience_toolkit.svg"
import { ReactComponent as SURVEY } from "../../assets/home_surveys.svg"
import { ReactComponent as REPORT } from "../../assets/home_my_reports.svg"
import HOME_ROUTE from '../../home/route';
import PAGE_ROUTE from '../../page/route';
import USER_ROUTE from '../../user/route';
import SURVEY_ROUTE from '../../survey/route';
import STORY_ROUTES from "../../story/route"
import TOOL_ROUTE from '../../tool/route';
import DASHBOARD_ROUTE from '../../dashboard/route';
import ORGANIZATION_ROUTE from '../../organization/route';
import Footer from './Footer';
import "../styles/Layout.css"

const PageLayout = props => {
  const { me, adminRoutes, active, path } = props;
  let history = useHistory();
  const [jwt, setJwt] = React.useState(null);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const handleLoggedIn = React.useRef(() => { });
  const [visible1, setVisible1] = React.useState(false);
  const [visible2, setVisible2] = React.useState(false);

  handleLoggedIn.current = async () => {
    const storedJwt = await getItem('accessToken');
    setTimeout(3000, setLoggedIn(storedJwt));
  };

  const handleLogout = () => {
    removeItem('accessToken');
    setJwt(null);
    window.location.pathname = HOME_ROUTE.home;
    // history.push(HOME_ROUTE.home);
  };

  const onSelect = params => {
    history.push(params.key);
  };

  React.useEffect(() => {
    handleLoggedIn.current();
  }, []);

  const diffBackgroundColorRoutes = [
    SURVEY_ROUTE.allOrganizationSurvey,
    SURVEY_ROUTE.surveyQuizLink,
    TOOL_ROUTE.genericToolLink,
    TOOL_ROUTE.genericToolAdminLink
  ].includes(window.location.pathname.replace(/[0-9]/g, ''));

  const sideNavbarRoutes = {
    [DASHBOARD_ROUTE.personal]: "Resilience Toolkit",
    [SURVEY_ROUTE.allOrganizationSurvey]: "Survey's",
    [USER_ROUTE.myResponse]: "My Reports",
    [USER_ROUTE.profile]: "My Profile"
  }

  const noNavbarRoutes = [SURVEY_ROUTE.surveyQuizLink].includes(window.location.pathname.replace(/[0-9]/g, ''));
  const noBackTopRoutes = [SURVEY_ROUTE.surveyQuizLink].includes(window.location.pathname.replace(/[0-9]/g, ''));
  
  console.log("Hello",(Object.keys(sideNavbarRoutes).includes(path) || Object.keys(sideNavbarRoutes).includes(props.location.pathname)),path);
  
  
  const MenuItems = (
    <>
      {IS_ADMIN_DROPDOWN ? (
        <>
          {loggedIn && me && me.isSuperuser && (
            <Menu.SubMenu className='menu-item' key="admin" icon={<DeploymentUnitOutlined />} /* title="Admin" */>
              {adminRoutes.map((r, idx) => (
                <Menu.Item key={`${idx}`} icon={r.icon}>
                  <a href={r.path}>{r.name}</a>
                </Menu.Item>
              ))}
              {NODE_ENV !== 'production' && (
                <Menu.Item className='menu-item' key={'graphiql'} icon={<ApiOutlined />}>
                  <a href={BACKEND_URI} target="_blank" rel="noopener noreferrer">
                    Graphiql
                  </a>
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )}
        </>
      ) : (
        loggedIn &&
        me &&
        me.isSuperuser && (
          <>
            <Menu.Item key="home">
              <a className={`menu-item ${!visible2 && "admin-menu"}`} href={ORGANIZATION_ROUTE.organization}>
                <DeploymentUnitOutlined />
              </a>
            </Menu.Item>
            {NODE_ENV !== 'production' && (
              <Menu.Item key={'graphiql'} rel="noopener noreferrer">
                <a className={`menu-item ${!visible2 && "admin-menu"}`} href={BACKEND_URI} target="_blank" without rel="noopener noreferrer">
                  <ApiOutlined />
                </a>
              </Menu.Item>
            )}
          </>
        )
      )}

      {loggedIn ? (
        <Menu.SubMenu
          className={
            visible2 || me?.profile?.profileImage
              ? 'menu-item'
              : 'menu-item menu-item-no-profile-pic'
          }
          key="profile"
          icon={visible2 ? <UserOutlined /> : (me?.profile?.profileImage && <img className='user-profile-picture' src={me.profile.profileImage} />)}
          title={
            visible2 ? "My Profile" : (
              !me?.profile?.profileImage && (me?.username.split(" ").length > 1
                ? `${me?.username.split(" ")[0][0]}${me?.username.split(" ")[me?.username.split(" ").length - 1][0]}`
                : me?.username.split(" ")[0][0]))}
        >
          <Menu.Item key="login" icon={visible2 ? <UserOutlined /> : null}>
            <a href={USER_ROUTE.profile}>{me ? me?.username : 'My Profile'}</a>
          </Menu.Item>
          <Menu.Item key="surveys" icon={visible2 ? <ReconciliationOutlined /> : null}>
            <a href={STORY_ROUTES.allStories}>Story</a>
          </Menu.Item>
          <Menu.Item key="faq" icon={visible2 ? <QuestionCircleOutlined /> : null}>
            <a href={PAGE_ROUTE.faqPage}>FAQ</a>
          </Menu.Item>
          <Menu.Item key="logout" onClick={handleLogout} icon={visible2 ? <LogoutOutlined /> : null}>
            Logout
          </Menu.Item>
        </Menu.SubMenu>
      ) : (
        <>
          <Menu.Item key="home" icon={visible2 ? <HomeOutlined /> : null}>
            <a className='menu-item' href={HOME_ROUTE.home}>Home</a>
          </Menu.Item>

          <Menu.Item key="surveys" icon={visible2 ? <ReconciliationOutlined /> : null}>
            <a className='menu-item' href={STORY_ROUTES.allStories}>Story</a>
          </Menu.Item>
          <Menu.Item key="faq" icon={visible2 ? <QuestionCircleOutlined /> : null}>
            <a className='menu-item' href={PAGE_ROUTE.faqPage}>FAQ</a>
          </Menu.Item>
          {visible2 ?
            (<Menu.Item key="login" icon={<LoginOutlined />}>
              {<a className='menu-item' href={USER_ROUTE.login}>Login</a>}
            </Menu.Item>)
            : (<Menu.Item key="login" style={{ height: "82px" }}>
              {<a className='landing-page-user-login' href={USER_ROUTE.login}>Login</a>}
            </Menu.Item>)}
        </>
      )}
    </>
  );
  const handleDrawer = params => {
    if (params === 1) {
      setVisible1(true);
      setVisible2(false);
    } else {
      setVisible1(false);
      setVisible2(true);
    }
  };

  return (
    <>
      {!loggedIn && !noNavbarRoutes && (
        <Affix>
          <Layout.Header className={"nav-background"}>
            <Row
              justify="space-between"
              align="middle"
              style={{
                margin: '0 auto',
                height: "90px"
              }}
            >
              <Col lg={0} xs={8} sm={8} md={8} style={{ lineHeight: '3', height: "100%" }}>
                <a href="/" className='site-logo'>
                  <img src={Logo} alt="logo" style={{ maxHeight: "80%", maxWidth: "80%" }} />
                </a>
              </Col>
              <Col lg={0} xs={8} sm={8} md={8} align="right" style={{ lineHeight: '3' }}>
                <ImportOutlined
                  onClick={() => handleDrawer(2)}
                  className='right-drawer-menu'
                  style={{ color: "#fff" }}
                />
              </Col>
              <Col className='logo-container-column' lg={12} xs={0} style={{ lineHeight: '3', height: "100%" }}>
                <Row style={{ height: "100%" }}>
                  <Col span={12} style={{ height: "100%" }}>
                    <a href="/" style={{ height: "100%", display: "flex", alignItems: "center" }}>
                      <img src={Logo} alt="logo" style={{ height: "70%" }} />
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} xs={0} style={{ lineHeight: '5' }}>
                <Row justify="end">
                  <Menu mode="horizontal" inlineIndent={50} style={{ background: "unset", border: 'none' }} selectedKeys={[]}>
                    {MenuItems}
                  </Menu>
                </Row>
              </Col>
            </Row>
            <Drawer   // Right Drawer
              width="190px"
              placement="right"
              bodyStyle={{ padding: '24px 0px' }}
              closable={false}
              onClose={() => setVisible2(false)}
              visible={visible2}
              style={{ color: "#fff" }}
            >
              <Menu mode="inline">{MenuItems}</Menu>
            </Drawer>
          </Layout.Header>
        </Affix>
      )}
      {loggedIn && !noNavbarRoutes && (
        <Affix>
          <Layout.Header className={"nav-background-logged-in"}>
            <Row
              justify="space-between"
              align="middle"
              style={{
                margin: '0 auto',
                height: "90px"
              }}
            >
              <Col lg={0} xs={8} sm={8} md={8} align="left" style={{ lineHeight: '3' }}>
                {loggedIn && (
                  <MenuOutlined
                    onClick={() => handleDrawer(1)}
                    className='left-drawer-menu'
                  />
                )}
              </Col>
              <Col className='site-logo-container-lg-0' lg={0} xs={8} sm={8} md={8} style={{ lineHeight: '3', height: "100%" }}>
                <a href={DASHBOARD_ROUTE.personal} className='site-logo'>
                  <img src={Logo_Dark} alt="logo" style={{ maxHeight: "80%", maxWidth: "80%" }} />
                </a>
              </Col>
              <Col lg={0} xs={8} sm={8} md={8} align="right" style={{ lineHeight: '3' }}>
                <ImportOutlined
                  onClick={() => handleDrawer(2)}
                  className='right-drawer-menu'
                />
              </Col>
              <Col lg={12} xs={0} style={{ lineHeight: '3', height: "100%" }}>
                <Row style={{ height: "100%" }}>
                  <Col span={12} style={{ height: "100%", display: Object.keys(sideNavbarRoutes).includes(path) ? "flex" : "block" }}>
                    <a href={DASHBOARD_ROUTE.personal} style={{ height: "100%", display: "flex", alignItems: "center" }}>
                      <img src={Object.keys(sideNavbarRoutes).includes(path) ? Logo : Logo_Dark} alt="logo" style={{ height: "70%" }} />
                    </a>
                    {(Object.keys(sideNavbarRoutes).includes(path) || Object.keys(sideNavbarRoutes).includes(props.location.pathname)) && <div className="navbar-section-title">
                      {path?sideNavbarRoutes[path]: sideNavbarRoutes[props.location.pathname]}
                    </div>}
                  </Col>
                </Row>
              </Col>
              <Col lg={12} xs={0} style={{ lineHeight: '5' }}>
                <Row justify="end" align='center'>
                  <Menu className={loggedIn ? "nav-menu-logged-in" : "nav-menu-logged-out"} mode="horizontal" align="center" inlineIndent={50} style={{ background: "unset", border: 'none' }}>
                    {MenuItems}
                  </Menu>
                </Row>
              </Col>
            </Row>
            {loggedIn && (   // Right Drawer
              <Drawer
                width="190px"
                bodyStyle={{ padding: '24px 0px' }}
                placement="left"
                closable={false}
                onClose={() => setVisible1(false)}
                visible={visible1}
              >
                <Menu mode="inline" defaultSelectedKeys={[`${active}`]} onSelect={onSelect} style={{ borderRight: "unset" }}>
                  {loggedIn && me?.isSuperuser && adminRoutes.map(ele => ele.path).includes(path) ? (
                    <>
                      {adminRoutes.map((r, idx) => (
                        <Menu.Item key={`${idx}`} icon={r.icon}>
                          <a href={r.path}>{r.name}</a>
                        </Menu.Item>
                      ))}
                    </>
                  ) : (
                    <>
                      <Menu.Item className='sidebar-drawer-menu'

                        key={DASHBOARD_ROUTE.personal}
                        icon={<TOOLKIT />}
                        style={{ paddingRight: '3vw' }}
                        onSelect={onSelect}
                      >
                        Resilience Toolkit
                      </Menu.Item>
                      <Menu.Item className='sidebar-drawer-menu' key={SURVEY_ROUTE.allOrganizationSurvey} icon={<SURVEY />} style={{ paddingRight: '3vw' }} onSelect={onSelect}>
                        Survey's
                      </Menu.Item>
                      <Menu.Item className='sidebar-drawer-menu' key={USER_ROUTE.myResponse} icon={<REPORT />} style={{ paddingRight: '3vw' }} onSelect={onSelect}>
                        My Reports
                      </Menu.Item>
                    </>
                  )}
                </Menu>
              </Drawer>
            )}
            <Drawer   // Left Drawer
              width="190px"
              placement="right"
              bodyStyle={{ padding: '24px 0px' }}
              closable={false}
              onClose={() => setVisible2(false)}
              visible={visible2}
              style={{ color: "#fff" }}
            >
              <Menu mode="inline">{MenuItems}</Menu>
            </Drawer>
          </Layout.Header>
        </Affix>
      )}
      <BodyDiv diffBackgroundColorRoutes={diffBackgroundColorRoutes}>{props.children}</BodyDiv>
      {!noBackTopRoutes && (
        <BackTop>
          <Tooltip placement="left" title="Back to Top" autoAdjustOverflow={true}>
            <Button type="primary" size="large" shape="circle" icon={<ArrowUpOutlined />}></Button>
          </Tooltip>
        </BackTop>
      )}
      {!loggedIn && !noNavbarRoutes && <Footer />}
    </>
  );
};

export default compose(
  graphql(ME_QUERY, {
    options: () => {
      return {
        fetchPolicy: 'network-only'
      };
    },
    props({ data: { loading, error, me, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, me, subscribeToMore, updateQuery };
    }
  })
)(PageLayout);

const BodyDiv = styled.div`
  position: relative;
  min-height: 100vh;
`;
