import React from 'react';
import { Row, Col, Button, Card } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getItem } from '../../core';
import { MetaTags } from '../../look';
import USER_ROUTE from '../../user/route';
import SURVEY_ROUTE from '../../survey/route';
import Background from '../../assets/background.gif';
import Foreground from '../../assets/foreground.gif';
import Research from '../../assets/features_search.svg';
import Assessment from '../../assets/features_assesment.svg';
import Toolkit from '../../assets/features_toolkit.svg';
import Stories from '../../assets/features_stories.svg';
import "../styles/homeView.css"

const HomeView = ({ isSurveyGiven }) => {
  const [accessToken, setaccessToken] = React.useState();
  async function getAccessToken() {
    const token = await getItem('accessToken');
    setaccessToken(token);
  }
  React.useEffect(() => {
    getAccessToken();
  }, []);

  const features = [
    {
      icon: Research,
      title: "Resilience<br />Research",
      description: "Resilience research has long history. Various researchers have been working on unraveling the secrets of resilience for many years and we can learn a lot from them. Explore some of the key findings by these researchers, as well as findings from the research Brainayan has been conducting."
    },
    {
      icon: Assessment,
      title: "Resilience<br />Assessment",
      description: "Self-awareness is the most important tool to be a better version of yourself. Our resilience assessments will provide you with insights that will help you build or fine-tune your resilience strategies."
    },
    {
      icon: Toolkit,
      title: "Resilience<br />Toolkit",
      description: "Self-awareness is the first step. Next comes action. Our resilience toolkit will offers techniques and tips to strengthen various aspects of your resilience. These practical tools will help find ways to turn insights you gather from our assessments into actionable commitments, so you can develop your resilience further."
    },
    {
      icon: Stories,
      title: "Resilience<br />Stories",
      description: "Stories have been at the centre of success of the human species. We have transmitted knowledge and made it stick with stories. You can find some stories of people that struggled with adversities and dealt with them effectively, emerging resilient and learn from their experience. And if you feel inspired, share your stories as well."
    }
  ]

  const surveys = [
    {
      img: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1732885759/WorkplaceResilienceProduction/Survey/Images/PerceptionSurvey_kz2ijh.png",
      survey_no: "01",
      title: "Perception Survey",
      description: "The Perception Survey measures your perception of your resilience at the workplace, as well as in your personal life. The first section of the survey requires you to rate yourself on how resilient you are overall, in your workplace, and in your personal life. In the second section of the survey, you’ll rate yourself on your use of various protective factors in your workplace and personal life. Your ratings are then compared with various demographic groups relevant to you, like people of your age, gender, education, and so on. These comparisons again allow you to see how you are placed with respect to these groups."
    },
    {
      img: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1732885759/WorkplaceResilienceProduction/Survey/Images/PredictionSurvey_agxajr.png",
      survey_no: "02",
      title: "Prediction Survey",
      description: "Our prediction survey uses a five-anchor model based on a meta-study of resilience research. The survey asks you to assess yourself on various behaviors, attitudes and beliefs that measure your strength in the five anchors. It then measures and presents your overall resilience and also unpacks each anchor for you calling out sub-factors in each acnhors that are your strengths or areas of opportunity. This survey presents an opportunity for your to review your resilience in detail and fine-tune your strategies to be resilient on a day to day basis."
    },
    {
      img: "https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1732885760/WorkplaceResilienceProduction/Survey/Images/SituationalJudgementSurvey_g2icwh.png",
      survey_no: "03",
      title: "Resilience Situational Judgement Survey",
      description: "The Resilience SJT is like a backstage pass to your decision making journey. You’ll encounter realistic workplace scenarios and choose responses based on your past experiences. These experiences assess not only your knowledge but also how you handle stress and respond to different situations. In short, it will assess how your amygdala hijack works. Be your authentic self while answering the questions. Resilience SJT helps employers to understand how you navigate the twists and turns one might encounter at the workplace. It will assess you on team work, capability, empathy etc. You will come across scenarios which will determine what trade-offs you have dealt with, what made you choose your choices."
    }
  ]

  return (
    <>
      <MetaTags title="Home" description="This is the home page" />
      <NewRow>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 9, offset: 3 }}>
          <Row style={{ paddingTop: '10vh' }}>
            <NewColumn>
              <NewH2>Welcome To Workplace Resilience</NewH2>
            </NewColumn>
            <NewColumn
            >
              <NewH1>Exploring & Building Resilience</NewH1>
            </NewColumn>
            <NewColumn
            >
              <NewH3>Discover practical strategies to strengthen your resilience and overcome challenges with confidence. Empower yourself to thrive in any situation, both personally and professionally.</NewH3>
            </NewColumn>
            <NewColumn>
              {accessToken ? (
                <SignUpButton>
                  <Link to={isSurveyGiven ? USER_ROUTE.myResponse : SURVEY_ROUTE.allOrganizationSurvey}>
                    {`${isSurveyGiven ? 'My Reports' : 'Give Survey'}`}
                  </Link>
                </SignUpButton>
              ) : (
                <SignUpButton>
                  <Link to={USER_ROUTE.register}>
                    Sign Up
                  </Link>
                </SignUpButton>
              )}
              <WatchVideoButton size="medium" type="primary">
                <Link to={"#"}>
                  Watch Video
                </Link>
              </WatchVideoButton>
            </NewColumn>
          </Row>
        </Col>
        <Col lg={12} xs={0} style={{ height: '100%', width: "100%", alignSelf: "end", position: "relative", bottom: "-10vh", overflow: "hidden" }}>
          <img style={{ height: '140%', width: '140%', objectFit: "cover", opacity: "60%", position: "relative", left: "-10vw" }} src={Foreground} alt="logo" />
        </Col>
      </NewRow>

      <Row justify="center">
        <Col lg={20} xs={22} style={{ marginTop: "5vh" }}>
          <Chip>Features</Chip>
          <SectionHeader>Workplace Resilience</SectionHeader>

          <Row gutter={[24, 24]}>
            {features.map(({ icon, title, description }, index) => (
              <Col
                key={index}
                xs={24}
                sm={12}
                md={12}
                lg={6}
              >
                <Card
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    backgroundColor: "#B9C47E1A",
                    borderRadius: "10px",
                    border: "unset"
                  }}
                >
                  <div style={{ textAlign: "left" }}>
                    <img
                      src={icon}
                      alt={`Icon ${index + 1}`}
                      style={{ width: "4vh", height: "4vh", marginBottom: "1rem" }}
                    />
                    <h2 style={{ fontWeight: "500", marginBottom: "1rem", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: title }} />
                    <div style={{ fontSize: "1rem", color: "#555" }}>{description}</div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <br />
          <br />
          <Chip style={{ justifySelf: "center" }}>Survey</Chip>
          <SectionHeader style={{ justifySelf: "center" }}>How it works</SectionHeader>
          <br />
          <br />

          {
            surveys.map(({ img, survey_no, title, description }, index) =>
              <Row style={{ paddingBottom: "5vh", justifyContent: 'center' }}>
                <Col lg={24} xs={22}>
                  <Row className={index % 2 == 0 ? "survey-row" : "survey-row-reverse"}>
                    <Col flex="33%" style={{ bottom: "-17px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <div
                        className='image-container'
                        style={{
                          fontSize: '8vh',
                          color: 'rgba(202, 202, 202, 0.25)',
                          marginBottom: '-1vh',
                          marginTop: '25px'
                        }}
                      >
                        <img src={img} alt="" style={{ width: "100%" }} />
                      </div>
                    </Col>
                    <SurveyColumn flex="63%" align="left">
                      <SurveyNumber>{survey_no}</SurveyNumber>
                      <h3 style={{ marginBottom: '10px', fontWeight: '500', fontSize: "xx-large", fontFamily: "Poppins" }}>{title}</h3>
                      <h4 style={{ marginBottom: '2vh' }}>
                        {description}
                      </h4>
                    </SurveyColumn>
                  </Row>
                </Col>
              </Row>
            )
          }
        </Col>
      </Row>
    </>
  );
};

export default HomeView;

const NewH1 = styled.h1`
  @media only screen and (max-width: 480px) {
    font-size: 4vh;
    font-weight: bold;
    text-align: center;
  }
  font-size: 7vh;
  background: linear-gradient(90deg, #FEBE44 4%, #B0CB1F 39.5%, #249AD9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const NewH2 = styled.h2`
  @media only screen and (max-width: 480px) {
    font-size: 2vh;
    font-weight: bold;
    text-align: center;
  }
  font-size: 3vh;
  color: #fff;
  font-weight: 700;
`;

const NewH3 = styled.h3`
  @media only screen and (max-width: 480px) {
    font-size: 2vh;
    text-align: center;
  }
  font-size: 3vh;
  color: #fff;
`;

const NewRow = styled(Row)`
  width: 100%; 
  padding-bottom: 10vh; 
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center;
  display: flex;
  @media only screen and (max-width: 992px) {
    padding: 0vw 7vw;
    padding-bottom: 5vh; 
  }
`;

const NewColumn = styled(Col)`
  @media only screen and (max-width: 992px) {
    width: 100%;
    justify-self: center;
    text-align: center;
  }
`;

const SignUpButton = styled(Button)`
  border-radius: 15px;
  background: #b0cb1f;
  color: #fff;
  font-size: small;
  font-weight: 800;
  border: unset;
  text-transform: uppercase;
  margin: 10px 10px 0px 0px;
`;

const WatchVideoButton = styled(Button)`
  border-radius: 15px;
  background: #00000000;
  color: #fff;
  font-size: small;
  font-weight: 800;
  border: 2px solid;
  margin: 10px 0px 0px 10px;
`;

const Chip = styled.h5`
  background: #B9C47E1A;
  color: #B0CB1F;
  border-radius: 15px;
  padding: 2px 12px;
  width: max-content;
  text-transform: uppercase;
`

const SectionHeader = styled.h1`
  font-size: xxx-large;
  font-weight: 700;
  @media only screen and (max-width: 992px) {
  font-size: xx-large;
  }
`

const SurveyRow = styled(Row)`
  display: flex
  &:nth-child(odd) {
    flex-direction: row;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
  }
`

const SurveyColumn = styled(Col)`
  padding-left: 2%;
  border-left: 1px solid #B0CB1F
`
const SurveyNumber = styled.h1`
  font-family: 'Poppins';
    font-size: 10vh;
    padding: 0px;
    margin: 0px;
    font-weight: 400;
    color: #B0CB1F;
`