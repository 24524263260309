import React from 'react';
import HomeView from '../components/HomeView';
import HomeView_V2 from '../components/HomeView_v2';
import { compose } from '../../core';
import { withMyResponse } from '../../user/containers/UserOperations';
import { NEW_LAYOUT } from '../../../config';

const Home = ({allResponsesByImplicitUserId}) => {
  return NEW_LAYOUT ? (
    <HomeView_V2 isSurveyGiven={allResponsesByImplicitUserId} />
  ) : (
    <HomeView isSurveyGiven={allResponsesByImplicitUserId} />
  );
};

export default compose(withMyResponse)(Home);
