import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Row, Col, Card, Button, Pagination, Spin, Tabs } from 'antd';
import styled from 'styled-components';

import { getIntFromString, MetaTags, ReactMarkdown, UserLayout } from '../../look';
import ROUTE from '../route';
import { PAGINATION_LIMIT, PRIMARY_CLR } from '../../../config';
import '../css/AllOrganizationSurveysView_v2.css'

import { ReactComponent as SURVEY_1 } from "../../assets/survey_1_perception.svg"
import { ReactComponent as SURVEY_2 } from "../../assets/survey_2_prediction.svg"
import { ReactComponent as SURVEY_7 } from "../../assets/survey_7_judgement.svg"
import { ReactComponent as SURVEY_8 } from "../../assets/survey_8_3cs_trust.svg"
import { ReactComponent as GENERIC } from "../../assets/survey_unavailable.svg"
import { ReactComponent as RIGHT_ARROW } from "../../assets/arrow_right.svg"
import { flow } from '@ant-design/charts';

const { TabPane } = Tabs;

const AllOrganizationSurveys = props => {
  const {
    pagination: { first, offset },
    allOrgSurveysByImplicitOrgIdLoading,
    loading,
    allOrgSurveysByImplicitOrgId,
    allPublicSurveys,
    onPaginationChange,
    me
  } = props;

  const surveyIcon = {
    "SurveyType:1": SURVEY_1,
    "SurveyType:2": SURVEY_2,
    "SurveyType:7": SURVEY_7,
    "SurveyType:8": SURVEY_8
  }

  const [activeTab, setActiveTab] = React.useState('1');

  const RenderItem = ({ node }) => {
    return (
      <div lg={24} align="center" className='survey-card-container'>
        <div className='survey-cards'>
          <div className='survey_card_icon'>
          {Object.keys(surveyIcon).includes(atob(node.id)) ? React.createElement(surveyIcon[atob(node.id)], { className: "home-sidebar-icons" }) : <GENERIC className='home-sidebar-icons'/>}
          </div>
          <div className='survey_card_content' align="left">
            <h2>{node.name}</h2>
            <div align="left">
              <ReactMarkdown>{node.description}</ReactMarkdown>
            </div>
            <Button icon={<RIGHT_ARROW />}>
              <Link to={`${ROUTE.surveyQuizLink}${getIntFromString(node.id)}`}>Start Survey</Link>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const pagination = totalCount => (
    <Col span={24} align="right">
      <Pagination
        current={(offset + first) / first}
        responsive={true}
        defaultCurrent={1}
        onChange={(page, pageSize) => onPaginationChange({ offset: page * pageSize - pageSize, first: pageSize })}
        showTotal={(total, range) => totalCount && `Showing ${range[0]} to ${range[1]} of ${total} entries.`}
        defaultPageSize={PAGINATION_LIMIT}
        total={totalCount}
      />
    </Col>
  );

  const assignedSurveyTab = (
    <Row gutter={[24, 24]}>
      {allOrgSurveysByImplicitOrgId && allOrgSurveysByImplicitOrgId.edges.length > 0 && (
        <>
          {allOrgSurveysByImplicitOrgId.edges.map(({ node, index }) => (
            <RenderItem node={node} key={index} />
          ))}
          {allOrgSurveysByImplicitOrgId && pagination(allOrgSurveysByImplicitOrgId.totalCount)}
        </>
      )}
    </Row>
  );
  const otherSurveyTab = (
    <Row style={{ zIndex: '1', height: '100%' }} gutter={[24, 24]}>
      {allPublicSurveys && allPublicSurveys.edges.length > 0 && (
        <>
          {allPublicSurveys.edges.map(({ node, index }) => (
            <RenderItem node={node} key={index} />
          ))}
          {allPublicSurveys && pagination(allPublicSurveys.totalCount)}
        </>
      )
      }
    </Row>
  );
  console.log('allOrgSurveysByImplicitOrgId::', allOrgSurveysByImplicitOrgId, allPublicSurveys)
  console.log(ROUTE)
  return (
    <>
      <UserLayout active={ROUTE.allOrganizationSurvey} me={me}>
        <MetaTags title="All Organization" description="This is All Organization page" />

        <Row justify="center" align="middle">
          {activeTab === '2' && <Spin gutter={[24, 24]} spinning={loading} className='spin_for_survey'></Spin>}
          {activeTab === '1' && <Spin gutter={[24, 24]} spinning={allOrgSurveysByImplicitOrgIdLoading} className='spin_for_survey'></Spin>}
          <Col xs={20} md={24}>
            {allOrgSurveysByImplicitOrgId?.edges?.length > 0 ? (
              <CutomTabs
                animated={{ inkBar: true, tabPane: true }}
                className='survey-tab-container'
                defaultActiveKey="1"
                /* centered */
                tabBarGutter={0}
                onChange={setActiveTab}
              >

                <TabPane tab={<h2>Assigned to you</h2>} key="1">
                  {assignedSurveyTab}
                </TabPane>
                <TabPane tab={<h2>Other Survey</h2>} key="2">
                  {otherSurveyTab}
                </TabPane>
              </CutomTabs>
            ) : (
              otherSurveyTab
            )}
          </Col>
        </Row>
      </UserLayout>
    </>
  );
};

export default AllOrganizationSurveys;

const CutomTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    display: grid !important;
    grid-template-columns: auto auto !important;
    gap: 1vw;
    background-color: #000;

    .ant-tabs-tab-btn {
      margin: auto;
    }
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }
`;

// const CutomTabs = styled(Tabs)`
//   position: relative;
//   .ant-tabs-nav {
//     position: relative;
//     padding: 0;
//   }

//   .ant-tabs-nav-list {
//     display: flex;
//     justify-content: space-around;
//     position: relative;
//   }

//   .ant-tabs-tab {
//     position: relative;
//     padding: 10px 20px;
//     text-align: center;
//     transition: color 0.3s ease-in-out;

//     h2 {
//       margin: 0;
//       font-size: 16px;
//       color: #fff;
//     }

//     &:hover h2 {
//       color: #afe223;
//     }
//   }

//   .ant-tabs-tab-active h2 {
//     color: #32332B !important;
//   }

//   /* The sliding background */
//   .ant-tabs-nav::after {
//     content: "";
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     height: 4px;
//     width: 100px; /* Initial width */
//     background-color: #afe223;
//     border-radius: 2px;
//     transform: translateX(0); /* Initial position */
//     transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
//   }

//   /* Use the active class to move the sliding background */
//   .ant-tabs-tab-active ~ .ant-tabs-nav::after {
//     transform: translateX(calc(var(--tab-index) * 100%));
//   }
// `;