import React from 'react';
import { Row, Col, Layout, Collapse } from 'antd';
import styled from 'styled-components';

import Logo from '../../assets/logo_dark.png';
import PAGE_ROUTE from '../../page/route';
import { NODE_ENV } from '../../../config';

const NewH3 = styled.h3`
  color: #0e0e2c;
  font-family: poppins
`;
const NewA = styled.a`
  color: #141414;
  text-decoration-line: underline;
`;

const Footer = () => {
  const { Panel } = Collapse;

  return (
    <>
      <Layout.Footer style={{ backgroundColor: '#B9C47E1A' }}>
        <Row style={{ padding: '3vh' }}>
          <Col lg={24} md={24} sm={0} xs={0}>
            <Row>
              <Col lg={{ span: 4, offset: 1 }} md={{ span: 4, offset: 0 }} style={{ paddingLeft: '4.1666%' }}>
                <img src={Logo} alt="" width={"80%"} style={{ marginBottom: '10px' }} />
              </Col>
              <Col lg={{ span: 5, offset: 1 }} md={{ span: 5, offset: 1 }} style={{ paddingLeft: '5.1666%', borderLeft: "1px solid #d9d9d9" }}>
                <NewH3>About Resilience</NewH3>
                <h4>
                  <NewA href={PAGE_ROUTE.about}>About Us</NewA>
                </h4>
              </Col>
              <Col lg={{ span: 5, offset: 1 }} md={{ span: 5, offset: 1 }} style={{ paddingLeft: '5.1666%', borderLeft: "1px solid #d9d9d9" }}>
                <NewH3>Quick links</NewH3>
                <h4>
                  <NewA href={PAGE_ROUTE.faqPage}>FAQ</NewA>
                  <br />
                  <NewA href={PAGE_ROUTE.termsAndCondition}>Terms and Conditions</NewA>
                  <br />
                  <NewA href={PAGE_ROUTE.privacy}> Privacy policy</NewA>
                </h4>
              </Col>
              <Col lg={{ span: 5, offset: 1 }} md={{ span: 5, offset: 1 }} style={{ paddingLeft: '5.1666%', borderLeft: "1px solid #d9d9d9" }}>
                <NewH3>Keep in touch</NewH3>
                <h4>
                  <NewA href={PAGE_ROUTE.contactUs}>Contact us</NewA>
                  <br />
                  {NODE_ENV !== 'production' && <NewA href="//#endregion">Need help?</NewA>}
                </h4>
              </Col>
            </Row>
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}>
            <Row justify="center">
              <Col xs={24} align="center">
                <img src={Logo} alt="" height={'40px'} style={{ marginBottom: '20px' }} />
              </Col>
            </Row>
            <br />
            <Collapse /* defaultActiveKey={['1']} */ expandIconPosition="right" ghost>
              <Panel header={<NewH3>About Resilience</NewH3>} key="1">
                <h4>
                  <NewA href={PAGE_ROUTE.about}>About Us</NewA>
                </h4>
              </Panel>
              <Panel header={<NewH3>Quick links</NewH3>} key="2">
                <h4>
                  <NewA href={PAGE_ROUTE.faqPage}>FAQ</NewA>
                  <br />
                  <br />
                  <NewA href={PAGE_ROUTE.termsAndCondition}>Terms and Conditions</NewA>
                  <br />
                  <br />
                  <NewA href={PAGE_ROUTE.privacy}> Privacy policy</NewA>
                </h4>
              </Panel>
              <Panel header={<NewH3>Keep in touch</NewH3>} key="3">
                <h4>
                  <NewA href={PAGE_ROUTE.contactUs}>Contact us</NewA>
                  <br />
                  <br />
                  {NODE_ENV !== 'production' && <NewA href="//#endregion">Need help?</NewA>}
                </h4>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Layout.Footer>
      <div className="disclaimer" style={{ width: "100%", height: "70px", display: "flex", justifyContent: "center", alignItems: 'center', backgroundColor: "#B9C47E33" }}>
        © 2024 · Resilience | All Rights Reserved.
      </div>
    </>
  );
};

export default Footer;
